import './index.scss'

//点击打开收起搜索区域
// $('[data-open="headerSearch"]').on('click', function () {
//   if ($(this).hasClass('search-show')) {
//     $(this).removeClass('search-show')
//     $('.guide-header .header-search').removeClass('active')
//     $('.root-mask-bottom').fadeOut(200)
//   } else {
//     $(this).addClass('search-show')
//     $('.guide-header .header-search').addClass('active')
//     $('.root-mask-bottom').fadeIn(200)
//   }
// })
//搜索输入框获取焦点时，显示搜索历史
$(".guide-header .header-search .search-form .search-form-input input").focus(function () {
  $(".guide-header .header-search .search-form .search-history").show()
}).blur(function () {
  setTimeout(() => {
    $(".guide-header .header-search .search-form .search-history").hide()
  }, 200);
});

//点击登录，打开登录弹窗
$('[data-open="logindialog"]').on('click', function () {
  $('.login-dialog').fadeIn(200).css('display', 'flex')
  $('.login-dialog .login-cont').show()
  $('.login-dialog .wx-login-cont,.login-dialog .bind-tel').hide()
  $('.root-mask').fadeIn(200)

  //显示已登陆，仅用于效果查看
  // $('.guide-header .user-icon').addClass('islogin')
  // $('.guide-cont .log-btn-mob').addClass('islogin')
})